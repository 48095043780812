@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .page-footer {
    .page-sticky-footer {
      background: #040a2b;
      min-height: 66px;
    }
    position: absolute !important;
    bottom: 0;
    min-height: 67px !important;
  }
  .country-chooser {
    margin-left: 15px !important;
  }
}
/* fix the footer menu */

.page-sticky-footer .page-sticky-footer__right {
  width: 150px;
  width: 150px\0;
}

.language-switcher-elc-l10n-domain {
  li {
    float: left\0;
  }
  .first {
    padding: 0 15px 0 20px\0;
  }
}

html[data-useragent*='MSIE 8.0'] .mobile_hidden {
  padding-right: 0px;
}

.mobile_hidden {
  padding-right: 35px\9\0;
}

@media only screen and (min-width: 641px) {
  .footer-legal .menu li {
    &:nth-child(3n + 3) {
      padding-right: 20px;
    }
    padding-right: 20px;
  }
  .page-sticky-footer .page-sticky-footer__right {
    right: 2%;
    .field-menu {
      float: right;
    }
    .menu > li {
      display: inline-block;
      float: left;
      &.last {
        padding-right: 0;
      }
    }
  }
  .product_brief__button--add-to-bag,
  .product_brief__sub-panel-buttons-container {
    width: 260px;
  }
}
/* footer css for ipad portrait */
@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: portrait) {
  .footer-legal .menu li.last {
    margin-top: -57px !important;
    margin-right: 74px !important;
  }
}
/* footer css for ipad landscape */
@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: landscape) {
  .page-navigation .level-3 {
    margin: 5px 0 !important;
  }
}

@font-face {
  font-family: 'Conv_OptimaEL-Text';
  src: url('#{$netstorage-font-path}OptimaDisplay/OptimaDisplay-Roman.woff2') format('woff2');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Optima Display Roman' !important;
}

.brand-aerin .page-main {
  font-family: 'Optima Display Roman' !important;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Optima Display Roman' !important;
  }
}

.woa-blog__content .ee-disco-more__label {
  font-family: 'Optima Display Roman' !important;
}

.brand-aerin .page-main {
  .text--short,
  label,
  .cart-item__qty {
    font-family: 'Optima Display Roman' !important;
  }
}

.brand-renutriv .product_brief__desc1 {
  display: block;
}

.page-sticky-footer .page-sticky-footer__right {
  margin-right: 0px;
}
